import { checkIfMileageSuspicious } from '@wkda/funnel-utils';
import { useEffect, useRef, useState } from 'react';
const UPPER_THRESHOLD_YEAR = 35000;
const LOWER_THRESHOLD_YEAR = 2500;
const SUSPICIOUS_MILEAGE_MESSAGE_DELAY = 0;
export function useSuspiciousMileageCheck(_ref) {
  let {
    builtDate,
    mileage,
    lowerThreshold = LOWER_THRESHOLD_YEAR,
    upperThreshold = UPPER_THRESHOLD_YEAR,
    delay = SUSPICIOUS_MILEAGE_MESSAGE_DELAY,
    type
  } = _ref;
  const [isSuspiciousMileage, setIsSuspiciousMileage] = useState(false);
  const timeout = useRef(null);
  const checkResult = checkIfMileageSuspicious({
    year: builtDate ? Number(builtDate) : undefined,
    mileage: Number(mileage),
    lowerThreshold,
    upperThreshold,
    type
  });
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (!checkResult) {
      setIsSuspiciousMileage(false);
    } else {
      timeout.current = window.setTimeout(() => {
        setIsSuspiciousMileage(true);
      }, delay);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [mileage, checkResult, delay]);
  return isSuspiciousMileage;
}