import React from 'react';
import { carleadCreationGetInitialProps, } from '@wkda/carlead-creation-components';
import {
  BaseLeadPage,
  CarleadHIWWhyChooseWkdaFaq,
  CarleadStepCompoundCustomerPopupCustomerCounter
} from '@wkda/marketing-components';

function CarleadCreation() {
  return (
    <BaseLeadPage
      form={CarleadStepCompoundCustomerPopupCustomerCounter}
      content={CarleadHIWWhyChooseWkdaFaq}
      showTerms={true}
    />
  );
}

CarleadCreation.getInitialProps = carleadCreationGetInitialProps;

export default CarleadCreation;
