function checkSuspiciousMileageByYear(year, mileage, lowerThreshold, upperThreshold) {
  if (isNaN(year) || year <= 0) return false;
  const howOldIsCar = new Date().getFullYear() - year;
  const calculatedUpperThreshold = howOldIsCar * upperThreshold;
  const calculatedLowerThreshold = howOldIsCar * lowerThreshold;
  return mileage < calculatedLowerThreshold || mileage > calculatedUpperThreshold;
}
function checkSuspiciousMileage(mileage, lowerThreshold, upperThreshold) {
  return mileage < lowerThreshold || mileage > upperThreshold;
}
export const CheckSuspiciousMileageMethod = {
  Normal: 0,
  ByYear: 1
};
export function checkIfMileageSuspicious(_ref) {
  let {
    year,
    mileage,
    lowerThreshold,
    upperThreshold,
    type = CheckSuspiciousMileageMethod.ByYear
  } = _ref;
  if (!mileage || !lowerThreshold || !upperThreshold || Number.isNaN(mileage)) {
    return false;
  }
  if (type === CheckSuspiciousMileageMethod.ByYear && typeof year === 'number') return checkSuspiciousMileageByYear(year, mileage, lowerThreshold, upperThreshold);else if (type === CheckSuspiciousMileageMethod.Normal) return checkSuspiciousMileage(mileage, lowerThreshold, upperThreshold);
  return false;
}